@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:  #f1f1f1 !important;
}

code {
  font-family: source-code-pro,black Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



::-webkit-scrollbar {
  width: 15px;
  height: 5px;
  padding-top: 20px;
}

::-webkit-scrollbar-track {
  background: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background: #42c544;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2bf153;
}

.writing-mode-vertical-rl {
  writing-mode: vertical-rl;
}

@layer components {
  .custom-select {
    @apply w-full border-none bg-transparent text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-0 appearance-none;
  }

  .custom-select option {
    @apply bg-white text-gray-800;
  }

  .custom-select:focus {
    @apply ring-0 border-none;
  }

  .custom-select-wrapper {
    @apply relative;
  }

  .custom-select-wrapper::after {
    @apply absolute top-1/2 right-3 transform -translate-y-1/2 pointer-events-none;
    content: "▼";
    color: #4a5568;
  }
}


.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}